<template>
  <!-- eslint-disable max-len -->
  <div class="best-product__content--item">
    <div class="infomation__favorite" @click="changeFavoriteStatus(template.id)">
      <img src="../../assets/images/heart-inactive.svg" :class="isFavorite(template.id) ? 'd-none' : ''">
      <img src="../../assets/images/heart-active.svg" :class="!isFavorite(template.id) ? 'd-none' : ''">
    </div>
    <router-link :to="{ name: 'ShopDetail', params: { id: template.id } }">
      <div class="infomation__image">
        <img :src="template.thumbnail" alt="" @load="loadImage" v-show="isLoadedImage"/>
        <PuSkeleton v-show="!isLoadedImage"></PuSkeleton>
      </div>
      <div class="infomation__content">
        <div class="d-flex align-items-center justify-content-between mb-2">
          <h3 class="mb-0 infomation__content--title template--name">{{ template.productName }}</h3>
          <span class="infomation__content--price">$ {{ getPrice(template) }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <p class="mb-0 infomation__content--description">
            {{ template.productDescription }}
          </p>
          <Star :star="template.star" class="d-flex align-items-center infomation__content--star"/>
        </div>
        <p class="mb-0 infomation__content--description">
          Design by <span class="color-black">{{ template.account.displayName }}</span>
        </p>
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import Star from '@/components/Star.vue';

export default {
  components: {
    Star,
  },
  props: {
    template: {
      type: Object,
    },
  },
  name: 'TemplateCardWithStar',
  data() {
    return {
      isLoadedImage: false,
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      currentUser: 'authentication/currentUser',
    }),
    ...mapState({
      wishlistTemplateIds: (state) => state.wishlist.wishlistTemplateIds,
    }),
  },
  methods: {
    ...mapActions({
      addWishlist: 'wishlist/addWishlist',
      removeWishlist: 'wishlist/removeWishlist',
    }),
    loadImage() {
      this.isLoadedImage = true;
    },
    changeFavoriteStatus(templateId) {
      if (this.currentUser.id) {
        if (this.isFavorite(templateId)) {
          this.removeWishlist({ productId: templateId });
        } else {
          this.addWishlist({ productId: templateId });
        }
      } else {
        this.$router.push('Login');
      }
    },
    isFavorite(templateId) {
      return this.wishlistTemplateIds.includes(templateId);
    },
    getPrice(template) {
      const size = template.productSize.toLowerCase();
      return template.masterProductType.meta['size-price'].filter((item) => item.size.toLowerCase() === size)[0].price;
    },
  },
};
</script>

<style scoped>
.template--name {
  font-size: 14px !important;
}
</style>
