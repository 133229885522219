<template>
  <!-- eslint-disable max-len -->
  <fragment>
    <section class="content-wrapper">
      <nav class="breadcrumb-show">
        <div class="container">
          <a href="javascript:(0)" class="breadcrumb-show__item" aria-current="page">Shop</a>
          <p class="breadcrumb-show__description">
            Print your desired look on ready-made shirts with various sizes and colors.
          </p>
        </div>
      </nav>
      <nav aria-label="breadcrumb " class="d-none d-md-block">
        <div class="container">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item"><a href="#">Shop</a></li>
            <li class="breadcrumb-item active" aria-current="page">Detail</li>
          </ol>
        </div>
      </nav>
      <div class="shop best-product pt-0">
        <div class="container">
          <div class="best-product__content">
            <div class="row">
              <div class="col-xl-6">
                <div class="shop__infomation">
                  <div class="position-relative">
                    <div class="d-flex align-items-center justify-content-between">
                      <h3 class="shop__infomation--title">
                        {{ template.productName }}
                      </h3>
                      <Star :star="template.star" class="d-flex align-items-center infomation__content--star"/>
                    </div>
                    <p class="mb-0 infomation__content--design">
                      Design by <span class="color-black">{{ designerName }}</span>
                    </p>
                  </div>
                  <p class="infomation__content--description">
                    <span class="color-black">
                      {{ template.productDescription }}
                    </span>
                  </p>
                  <p class="infomation__content--price">$ {{ templatePrice }}</p>
                </div>
                <hr class="d-none d-xl-block" />
                <div class="shop__info-action d-none d-xl-block">
                  <div class="shop__info-action--item">
                    <h5 class="title">Color</h5>
                    <div class="design__tool--content">
                      <div class="color-choices" v-for="templateColor in templateColors" :key="templateColor">
                        <input type="radio" :value="templateColor" :id="templateColor" name="color" v-model="color"/>
                        <label :for="templateColor" class="radio" :style="{'background': `#${templateColor}`}"></label>
                      </div>
                    </div>
                  </div>
                  <div class="shop__info-action--item size-option">
                    <h5 class="title">Size</h5>
                    <div class="size__option">
                      <div class="color-choices size__option--item" v-for="templateSize in templateSizes" :key="templateSize">
                        <input type="radio" :value="templateSize" :id="templateSize" name="size" v-model="size"/>
                        <label :for="templateSize" class="radio">
                          {{ templateSize }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="shop__info-action--item mb-0">
                    <h5 class="title">Quantity</h5>
                    <div class="quality__control">
                      <button class="btn p-0" @click="reduceQuantity()">
                        <img src="../assets/images/minus-control.png" alt="" />
                      </button>
                      <input type="text" :value="quantity" />
                      <button class="btn p-0" @click="increaseQuantity()">
                        <img src="../assets/images/plus-control.png" alt="" />
                      </button>
                    </div>
                  </div>
                  <div class="shop__info-action--button my-3 my-lg-4">
                    <div class="row">
                      <div class="col-md-4">
                        <button class="btn btn-primary-2 w-100">Add to cart</button>
                      </div>
                      <div class="col-md-4">
                        <button
                          :class="['btn btn-primary btn-favorite w-100 d-flex align-items-center justify-content-center', isFavorite ? 'active' : '']"
                          @click="changeFavoriteStatus()"
                        >
                          Favourite
                          <span class="ms-2"
                            >
                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.03342 1.74062C1.82221 1.74062 0.836855 2.71744 0.836855 3.92871C0.836855 5.3982 1.52217 6.57561 2.3846 7.45039C3.25117 8.32938 4.27551 8.87992 4.88854 9.08707L4.89187 9.0882V9.08824C4.92146 9.09855 4.98275 9.10958 5.06102 9.10958C5.1393 9.10958 5.20058 9.09855 5.23017 9.08824L5.23351 9.08707C5.84653 8.87992 6.87088 8.32938 7.73745 7.45039C8.59987 6.57561 9.28519 5.3982 9.28519 3.92871C9.28519 2.71744 8.29984 1.74062 7.08862 1.74062C6.37522 1.74062 5.73552 2.08381 5.33598 2.61638C5.27136 2.70251 5.16939 2.75329 5.06102 2.75329C4.95266 2.75329 4.85068 2.70251 4.78606 2.61638C4.3863 2.08353 3.75106 1.74062 3.03342 1.74062ZM0.151855 3.92871C0.151855 2.3461 1.4407 1.0625 3.03342 1.0625C3.82653 1.0625 4.54252 1.38166 5.06116 1.89646C5.58094 1.38151 6.29959 1.0625 7.08862 1.0625C8.68134 1.0625 9.97019 2.3461 9.97019 3.92871C9.97019 5.62379 9.17591 6.96223 8.22764 7.92404C7.28421 8.88096 6.16775 9.48757 5.45636 9.7283C5.33109 9.77175 5.18766 9.78771 5.06102 9.78771C4.93439 9.78771 4.79095 9.77175 4.66569 9.7283C3.95431 9.48757 2.83783 8.88096 1.89439 7.92404C0.946145 6.96223 0.151855 5.62379 0.151855 3.92871Z" fill="#2A2D46" stroke="#2A2D46" stroke-width="0.3"/>
                            </svg>
                          </span>
                        </button>
                      </div>
                      <div class="col-md-4">
                        <button class="btn btn-primary w-100">Contact us</button>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="shop__detail-description">
                    <h5 class="shop__detail-description--title">Detail</h5>
                    <div v-html="template.productDescriptionDetail" class="shop__detail-description--content">
                      {{ template.productDescriptionDetail }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="shop__image ms-xl-3">
                  <div class="row d-none d-md-flex">
                    <div class="col-md-8 col-xl-12 shop__image--main">
                      <img :src="template.thumbnail" class="shop__image--item template__preview" alt="" />
                    </div>
                    <div class="col-md-4 col-xl-12">
                      <div class="row">
                        <div class="col-xl-6 shop__image--sub">
                          <img :src="firstPreview" class="shop__image--item template__preview" alt="../assets/images/shop-img.png" />
                        </div>
                        <div class="col-xl-6 shop__image--sub">
                          <img :src="secondPreview" class="shop__image--item template__preview" alt="../assets/images/shop-img.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- start mobile image -->
                  <div class="d-block d-md-none">
                    <div class="shop__image--slider">
                      <img :src="template.thumbnail" class="shop__image--item template__preview" alt="" />
                      <img :src="firstPreview" class="shop__image--item template__preview" alt="" />
                      <img :src="secondPreview" class="shop__image--item template__preview" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="shop__info-action d-block d-xl-none">
                <hr />
                <div class="shop__info-action--item">
                  <h5 class="title">Color</h5>
                  <div class="design__tool--content">
                    <div class="color-choices" v-for="templateColor in templateColors" :key="templateColor">
                      <input type="radio" :value="templateColor" :id="templateColor" name="m-color" v-model="color"/>
                      <label :for="templateColor" class="radio" :style="{'background': `#${templateColor}`}"></label>
                    </div>
                  </div>
                </div>
                <div class="shop__info-action--item size-option">
                  <h5 class="title">Size</h5>
                  <div class="size__option">
                    <div class="color-choices size__option--item" v-for="templateSize in templateSizes" :key="templateSize">
                      <input type="radio" :value="templateSize" :id="templateSize" name="m-size" v-model="size"/>
                      <label :for="templateSize" class="radio d-flex align-items-center justify-content-center">
                        {{ templateSize }}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="shop__info-action--item mb-0">
                  <h5 class="title">Quantity</h5>
                  <div class="quality__control">
                    <button class="btn p-0" @click="reduceQuantity()">
                      <img src="../assets/images/minus-control.png" alt="" />
                    </button>
                    <input type="text" :value="quantity" />
                    <button class="btn p-0" @click="increaseQuantity()">
                      <img src="../assets/images/plus-control.png" alt="" />
                    </button>
                  </div>
                </div>
                <div class="shop__info-action--button my-3 my-lg-4">
                  <div class="row">
                    <div class="col-lg-8">
                      <div class="row">
                        <div class="col-6 col-md-4 mb-3 mb-md-0">
                          <button class="btn btn-primary-2 w-100">Add to cart</button>
                        </div>
                        <div class="col-6 col-md-4 mb-3 mb-md-0">
                          <button
                            class="btn btn-primary w-100 d-flex align-items-center justify-content-center"
                          >
                            Favourite
                            <span class="ms-2"
                              ><svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.03342 1.74062C1.82221 1.74062 0.836855 2.71744 0.836855 3.92871C0.836855 5.3982 1.52217 6.57561 2.3846 7.45039C3.25117 8.32938 4.27551 8.87992 4.88854 9.08707L4.89187 9.0882V9.08824C4.92146 9.09855 4.98275 9.10958 5.06102 9.10958C5.1393 9.10958 5.20058 9.09855 5.23017 9.08824L5.23351 9.08707C5.84653 8.87992 6.87088 8.32938 7.73745 7.45039C8.59987 6.57561 9.28519 5.3982 9.28519 3.92871C9.28519 2.71744 8.29984 1.74062 7.08862 1.74062C6.37522 1.74062 5.73552 2.08381 5.33598 2.61638C5.27136 2.70251 5.16939 2.75329 5.06102 2.75329C4.95266 2.75329 4.85068 2.70251 4.78606 2.61638C4.3863 2.08353 3.75106 1.74062 3.03342 1.74062ZM0.151855 3.92871C0.151855 2.3461 1.4407 1.0625 3.03342 1.0625C3.82653 1.0625 4.54252 1.38166 5.06116 1.89646C5.58094 1.38151 6.29959 1.0625 7.08862 1.0625C8.68134 1.0625 9.97019 2.3461 9.97019 3.92871C9.97019 5.62379 9.17591 6.96223 8.22764 7.92404C7.28421 8.88096 6.16775 9.48757 5.45636 9.7283C5.33109 9.77175 5.18766 9.78771 5.06102 9.78771C4.93439 9.78771 4.79095 9.77175 4.66569 9.7283C3.95431 9.48757 2.83783 8.88096 1.89439 7.92404C0.946145 6.96223 0.151855 5.62379 0.151855 3.92871Z" fill="#2A2D46" stroke="#2A2D46" stroke-width="0.3"/>
                                </svg>
                            </span>
                          </button>
                        </div>
                        <div class="col-6 col-md-4 mb-3 mb-md-0">
                          <button class="btn btn-primary w-100">Contact us</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="shop__detail-description">
                  <h5 class="shop__detail-description--title">Detail</h5>
                  <div v-html="template.productDescriptionDetail" class="shop__detail-description--content"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="product-more">
      <div class="container">
        <div class="d-flex align-items-center justify-content-between">
          <h3 class="title-head">You may also like</h3>
          <button
            class="btn best-product__see-more d-none align-items-center position-relative d-md-flex"
          >
            See more
            <svg
              class="ms-2"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.75074 8.40527C1.33612 8.40527 1 8.06916 1 7.65453C1 7.23991 1.33612 6.90379 1.75074 6.90379L14.2432 6.90379C14.6578 6.90379 14.9939 7.23991 14.9939 7.65453C14.9939 8.06915 14.6578 8.40527 14.2432 8.40527L1.75074 8.40527Z"
                fill="#FF434D"
              />
              <rect
                width="1.50372"
                height="7.99655"
                rx="0.75186"
                transform="matrix(0.707107 0.707107 0.707107 -0.707107 8.59033 12.2451)"
                fill="#FF434D"
              />
              <path
                d="M9.12198 3.59512C8.82836 3.3015 8.82836 2.82545 9.12198 2.53183C9.4156 2.23821 9.89165 2.23821 10.1853 2.53183L14.7764 7.12295C15.07 7.41657 15.07 7.89262 14.7764 8.18624C14.4828 8.47986 14.0067 8.47986 13.7131 8.18624L9.12198 3.59512Z"
                fill="#FF434D"
              />
            </svg>
          </button>
        </div>
        <div class="best-product__content">
          <div class="row product-more__content-also">
            <div class="col-md-6 col-lg-4 col-xl-3" v-for="item in alsoLikeTemplates" :key="item.id">
              <template-card-with-star :template="item"/>
            </div>
          </div>
        </div>
      </div>
    </section>
  </fragment>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import Star from '@/components/Star.vue';
import TemplateCardWithStar from '@/components/templates/TemplateCardWithStar.vue';

export default {
  components: {
    Star,
    TemplateCardWithStar,
  },
  data() {
    return {
      color: '',
      sizePrices: [],
      size: '',
      quantity: 1,
      firstPreview: '',
      secondPreview: '',
    };
  },
  computed: {
    ...mapGetters({
      wishlistTemplateIds: 'wishlist/wishlistTemplateIds',
      masterTypesList: 'masterTypes/masterTypeList',
      template: 'template/template',
      currentUser: 'authentication/currentUser',
      alsoLikeTemplates: 'template/alsoLikeTemplates',
    }),
    designerName() {
      if (this.template.account) return this.template.account.displayName;
      return '';
    },
    templateColors() {
      if (this.template.masterProductType) return this.template.masterProductType.meta.color;
      return [];
    },
    templatePrice() {
      if (this.sizePrices.length) {
        return this.sizePrices.find((item) => item.size.toUpperCase() === this.size).price;
      }
      return 0;
    },
    templateSizes() {
      return this.sizePrices.map((item) => item.size.toUpperCase());
    },
    isFavorite() {
      return this.wishlistTemplateIds.includes(this.template.id);
    },
  },
  watch: {
    $route(route) {
      this.getData(route.params.id).then((res) => {
        this.initData(res);
      });
      window.scrollTo(0, 0);
    },
  },
  methods: {
    ...mapActions({
      getWishlists: 'wishlist/getWishlists',
      getMasterTypes: 'masterTypes/getMasterTypes',
      getTemplate: 'template/getTemplateById',
      getAlsoLikeTemplates: 'template/getAlsoLikeTemplates',
      addWishlist: 'wishlist/addWishlist',
      removeWishlist: 'wishlist/removeWishlist',
    }),
    increaseQuantity() {
      this.quantity += 1;
    },
    reduceQuantity() {
      if (this.quantity > 1) this.quantity -= 1;
    },
    initData(data) {
      if (data) {
        this.sizePrices = data.masterProductType.meta['size-price'];
        this.size = data.productSize.toUpperCase();
        this.color = data.productColor;
        this.firstPreview = data.productDesignPreview[0].preview;
        this.secondPreview = data.productDesignPreview[1].preview;
      }
    },
    async getData(templateId) {
      if (this.currentUser.id) await this.getWishlists();
      const data = await this.getTemplate(templateId);
      await this.getAlsoLikeTemplates({ id: templateId, typeId: data.masterProductTypeId });
      return this.getTemplate(templateId);
    },
    changeFavoriteStatus() {
      if (this.currentUser.id) {
        if (this.isFavorite) {
          this.removeWishlist({ productId: this.template.id });
        } else {
          this.addWishlist({ productId: this.template.id });
        }
      } else {
        this.$router.push('Login');
      }
    },
  },
  created() {
  },
  async mounted() {
    const templateId = this.$route.params.id;
    const targetTemplate = await this.getData(templateId);
    this.initData(targetTemplate);
  },
};
</script>

<style scoped>
  .template__preview {
    width: 100%;
  }
</style>
